import React, { useCallback, useEffect, useState } from "react";
import { faExchangeAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Table,
} from "reactstrap";

import InformationModal from "../../components/InformationModal";

import { ACTIONS, useSync } from "../../providers/syncProvider";

import { syncApi } from "../../services/syncServices";
import ConfirmationModal from "../../components/ConfirmationModal";
import TooltipItem from "../../components/TooltipItem";
import { utils } from "../../utils/utils";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import Loader from "../../components/Loader";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const AttendanceSync = () => {
  const [syncContext, setSyncContext] = useSync();

  const [itemLoading, setItemLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          syncContext.sortBy === sortBy.id &&
          syncContext.direction === direction
        ) {
          return;
        }
        setSyncContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setSyncContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [setSyncContext, syncContext.direction, syncContext.sortBy]
  );

  const onSync = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        try {
          setLoading(true);
          await syncApi.doTASync();
          setLoading(false);
          setSyncContext({
            action: ACTIONS.REFRESH,
          });
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: `TA Data Sync`,
            body:
              err.response.data.msg || "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "TA Data Sync",
      body: `<span class="text-center">
          This will trigger a data sync and new data will be added, do you want to continue?
        </span>`,
      confirmColor: "warning",
    });
  };

  const onSeeResults = async (id) => {
    try {
      setItemLoading({ [id]: true });
      const item = await syncApi.getTASyncs({ id });
      setItemLoading({ [id]: false });
      setInformationModal({
        isOpen: true,
        title: `TA Data Sync Details`,
        size: "lg",
        body: (
          <Table className="col-12 px-0 border rounded mb-0">
            <thead className="small">
              <tr className="text-muted bg-lighter">
                <th className="align-middle">Job</th>
                <th className="align-middle">Labor Code</th>
                <th className="align-middle">Reg. Hours</th>
                <th className="align-middle">Ovt. Hours</th>
              </tr>
            </thead>
            <tbody>
              {item.timeAttendanceLaborCodeHours.length ? (
                item.timeAttendanceLaborCodeHours.map(
                  (timeAttendanceLaborCodeHour) => {
                    const code =
                      timeAttendanceLaborCodeHour.laborCategory?.code ||
                      timeAttendanceLaborCodeHour.managementRole?.code;
                    const name =
                      timeAttendanceLaborCodeHour.laborCategory?.name ||
                      timeAttendanceLaborCodeHour.managementRole?.name;
                    return (
                      <tr key={timeAttendanceLaborCodeHour.id}>
                        <td className="align-middle">
                          {timeAttendanceLaborCodeHour.job.jobNumber}
                        </td>
                        <td className="align-middle">{`${code} - ${name}`}</td>
                        <td className="align-middle">
                          {timeAttendanceLaborCodeHour.reg} hours
                        </td>
                        <td className="align-middle">
                          {timeAttendanceLaborCodeHour.ovt} hours
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className="d-flex flex-column">
                      <p className="text-center">No imports found.</p>
                      <small>
                        This can be caused bc there are no jobs in this system
                        matching the ones in the XML from TA.
                      </small>
                      <small>
                        The attribute to match the job id in the XML is RFQ #.
                      </small>
                      <small>A posterior sync overwrote this syncing.</small>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        ),
        rawBody: true,
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const columns = [
    {
      accessor: "reportName",
      Header: "Report",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { reportName } = rowData.row.original;
        return reportName || "-";
      },
    },
    {
      accessor: "size",
      Header: "Size (kb)",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { size } = rowData.row.original;
        return size ? (size / 1000).toFixed(2) : "-";
      },
    },
    {
      accessor: "updatedAt",
      Header: "Date/time",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { updatedAt } = rowData.row.original;
        return updatedAt ? utils.formatDateTime(updatedAt) : "-";
      },
    },
    {
      accessor: "id",
      Header: "Actions",
      disableSortBy: true,
      headerProps: { className: "text-truncate text-right" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) =>
        itemLoading[rowData.row.original.id] ? (
          <Loader size="sm" align="end" />
        ) : (
          <Button
            size="sm"
            className="rounded float-right"
            onClick={() => onSeeResults(rowData.row.original.id)}
          >
            See Results
          </Button>
        ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const items = await syncApi.getTASyncs({
          page: syncContext.page - 1,
          pageSize: syncContext.sizePerPage,
          sortBy: syncContext.sortBy,
          direction: syncContext.direction,
        });
        setLoading(false);
        setSyncContext({
          action: ACTIONS.GET_SYNC_SUCCESS,
          payload: { items },
        });
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [
    setSyncContext,
    syncContext.sortBy,
    syncContext.direction,
    syncContext.sizePerPage,
    syncContext.page,
    syncContext.refresh,
  ]);

  return (
    <Container fluid className="h-100">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={syncContext.items.data || []}
          pageSize={syncContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: syncContext.sortBy,
            direction: syncContext.direction,
          }}
        >
          <Card className="mb-3 w-100 overflow-x-auto">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Time Attendance Data Sync</h3>
                <small className="text-muted ml-2 pt-1">
                  ({syncContext.items.count})
                </small>
              </div>
              <div className=" d-flex align-items-center justify-content-between">
                <div></div>
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex  mx-2 rounded"
                  color="primary"
                  onClick={() =>
                    setSyncContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
                <TooltipItem id="sync-tooltip" title="Fetch data from TA">
                  <Button
                    size="sm"
                    className="rounded-circle custom-rounded-button d-flex"
                    color="primary"
                    onClick={onSync}
                  >
                    <FontAwesomeIcon
                      icon={faExchangeAlt}
                      className="text-white"
                    />
                  </Button>
                </TooltipItem>
              </div>
            </CardHeader>
            <CardBody>
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={syncContext.items.count}
                pageCount={syncContext.items.totalPages}
                currentPage={syncContext.page - 1}
                onPageChange={(page) =>
                  setSyncContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={syncContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setSyncContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default AttendanceSync;
