import React from "react";

import { Card, CardBody, CardHeader } from "reactstrap";

import Loader from "../../Loader";
import { useJobKPIs } from "../../../providers/jobsKPIsProvider";
import { utils } from "../../../utils/utils";
import Header from "../../Header";
import { useNavigate } from "react-router-dom";

const JobsCards = () => {
  const navigate = useNavigate();
  const [jobKPIsContext] = useJobKPIs();

  return (
    <Header className="row">
      <div className="col-12 col-md-6 col-lg-3">
        <Card>
          <CardHeader>
            <div className="pb-1 border-bottom text-center">New RFQ's</div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/rfq/in-progress")}
                className="cursor-pointer text-xl text-center font-500"
              >
                {utils.formatDecimal(jobKPIsContext.recent)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Card>
          <CardHeader>
            <div className="pb-1 border-bottom text-center">Pending</div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/rfq/pending")}
                className="cursor-pointer text-xl text-center font-500"
              >
                {utils.formatDecimal(jobKPIsContext.inProgress)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Card>
          <CardHeader>
            <div className="pb-1 border-bottom text-center">
              In Project Management
            </div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/jobs/in-project-management")}
                className="cursor-pointer text-xl text-center font-500"
              >
                {utils.formatDecimal(jobKPIsContext.wonThisMonth)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Card>
          <CardHeader>
            <div className="pb-1 border-bottom text-center">In Progress</div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {jobKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/jobs/in-progress")}
                className="cursor-pointer text-xl text-center font-500"
              >
                {utils.formatDecimal(jobKPIsContext.lostThisMonth)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </Header>
  );
};

export default JobsCards;
